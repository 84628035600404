<template>
  <div class="flex justify-center my-3">
    <div class="text-center">
      <h3>{{ t('share') }}</h3>

      <div v-if="mounted" class="flex flex-wrap py-3">
        <ShareNetwork
          network="facebook"
          :url="link"
          :title="material.name"
          :description="material.name"
          :quote="material.name"
          hashtags="e-store"
          class="mx-1"
          tag="button"
          aria-label="facebook"
        >
          <div class="bg-facebook icon-class">
            <v-icon icon="mdi-facebook" :size="28" />
          </div>
        </ShareNetwork>
        <ShareNetwork
          network="twitter"
          :url="link"
          :title="material.name"
          :description="material.name"
          :quote="material.name"
          hashtags="e-store"
          class="mx-1"
          tag="button"
          aria-label="twitter"
        >
          <div class="bg-twitter icon-class">
            <v-icon icon="mdi-twitter" :size="28" />
          </div>
        </ShareNetwork>
        <ShareNetwork
          network="Pinterest"
          :url="link"
          :title="material.name"
          :description="material.name"
          :quote="material.name"
          hashtags="e-store"
          class="mx-1"
          tag="button"
          aria-label="pinterest"
        >
          <div class="bg-pinterest icon-class">
            <v-icon icon="mdi-pinterest" :size="28" />
          </div>
        </ShareNetwork>
        <ShareNetwork
          network="whatsapp"
          :url="link"
          :title="material.name"
          hashtags="e-store"
          class="mx-1"
          tag="button"
          aria-label="whatsapp"
        >
          <div class="bg-whatsapp icon-class">
            <v-icon icon="mdi-whatsapp" :size="28" />
          </div>
        </ShareNetwork>
        <ShareNetwork
          network="telegram"
          :url="link"
          :title="material.name"
          hashtags="e-store"
          class="mx-1"
          tag="button"
          aria-label="telegram"
        >
          <div class="bg-telegram icon-class">
            <v-icon icon="fa: fa-brands fa-telegram" :size="25" />
          </div>
        </ShareNetwork>
      </div>
      <div v-else class="h-14"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import VueSocialSharing from 'vue-social-sharing'
import type { Material } from '~~/composables/useMenuModel'

const props = defineProps<{
  material: Material
}>()

useNuxtApp().vueApp.use(VueSocialSharing)

const mounted = ref(false)
const { url } = useDomainHost()
const { t } = useI18n()

const link = computed(() => {
  return `${url}/materials/${props.material.id}`
})

onMounted(() => {
  mounted.value = true
})
</script>

<style scoped>
.icon-class {
  @apply mx-1 flex justify-center text-white items-center w-8 h-8 p-1 rounded-full;
}
</style>
